import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import help from '@/assets/images/help.svg';
import connect from '@/assets/images/connect.svg';

import '@/assets/styles/settings.scss';

import { me, logout } from '@/store/slices/userSlice';

const { REACT_APP_FACEBOOK_CLIENT_ID, REACT_APP_URL } = process.env;

function Connect() {
    const dispatch = useDispatch();

    const { profile, posts, profileType } = useSelector(state => state.user);

    const [pageId, setPageId] = useState('');
    const [intervalObserver, setIntervalObserver] = useState('');

    Wix.getCurrentPageId(id => {
        setPageId(id);
    });

    useEffect(() => {
        if (!pageId) {
            return;
        }

        dispatch(me(pageId));
    }, [pageId]);

    useEffect(() => {
        if (!profile) {
            return;
        }

        clearInterval(intervalObserver);

        Wix.Settings.triggerSettingsUpdatedEvent({ type: 'CONNECT', userData: { profile, posts, profileType } });
    }, [profile]);

    const connectPersonalAccount = () => {
        const redirectUri = `${REACT_APP_URL}/auth`;

        window.open(
            `https://instagram.com/oauth/authorize?client_id=${REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code&state=${pageId}`
        );

        const meInterval = setInterval(() => {
            dispatch(me(pageId));
        }, 1000);

        setIntervalObserver(meInterval);
    };

    const disconnect = () => {
        dispatch(logout(pageId));

        Wix.Settings.triggerSettingsUpdatedEvent({ type: 'DISCONNECT' });
    };

    return (
        <div className="connect-buttons-container">
            {!profile ? (
                <>
                    <button className="connect-button" style={{ marginBottom: 10 }}>
                        <img className="connect" src={connect} alt="connect" />
                        Connect Business Account
                    </button>
                    <button className="connect-button" onClick={connectPersonalAccount}>
                        <img className="connect" src={connect} alt="connect" />
                        Connect Personal Account
                    </button>
                </>
            ) : (
                <button className="connect-button" style={{ marginBottom: 10 }} onClick={disconnect}>
                    <img className="connect" src={connect} alt="connect" />
                    Disconnect
                </button>
            )}
            <div className="connect-help">
                <img className="help" src={help} alt="help" />
                <a href="#">How to connect your instagram?</a>
            </div>
        </div>
    );
}

export default Connect;
