import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const settings = {
    layout: {
        layout: 'grid',
        ratio: 'ratio1x1',
        direction: 'ltr',
        posts: '20',
        columns: 3,
        rows: 3,
        spacing: 12
    },
    display: {
        removeWatermark: false,
        profileImage: true,
        name: true,
        userName: true,
        followButton: true,
        numberOfPosts: true,
        followers: true,
        following: true,
        bio: true,
        loadMore: true
    },
    design: {
        font: { family: 'Open Sans', id: 'open-sans' },
        bioFontSize: 16,
        usernameFontSize: 28,
        cornerRadius: 0,
        textColor: '#000',
        backgroundColor: '#fff',
        buttonsColor: '#116dff',
        buttonsTextColor: '#fff',
        customCSS: ''
    },
    general: {
        openLinks: 'fullScreen'
    }
};

const defaultSettings = {
    desktop: settings,
    mobile: settings
};

export const getSettings = createAsyncThunk('settings/getSettings', async pageId => {
    const { data } = await axios.get(`/settings/${pageId}`);

    return data;
});

export const saveSettings = createAsyncThunk('settings/saveSettings', async ({ pageId, settings }) => {
    const { data } = await axios.post(`/settings/${pageId}`, {
        settings
    });

    return data;
});

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        settings: defaultSettings
    },
    extraReducers: builder => {
        builder
            .addCase(getSettings.fulfilled, (state, action) => {
                state.settings = action.payload;
            })
            .addCase(saveSettings.fulfilled, (state, action) => {
                state.settings = action.payload;
            });
    }
});

export default settingsSlice.reducer;
