import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { personalLogin } from '@/store/slices/userSlice';

function Settings() {
    const { profile } = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const authCode = searchParams.get('code');
        const pageId = searchParams.get('state');

        dispatch(personalLogin({ authCode, pageId }));
    }, []);

    useEffect(() => {
        if (!profile) {
            return;
        }

        window.close();
    }, [profile]);

    return <div />;
}

export default Settings;
