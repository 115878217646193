import { useState, useEffect } from 'react';

import '@/assets/styles/slider.scss';

function Slider({ title, min, max, value, valueSuffix = '', deviceType, style, setValue }) {
    const [background, setBackground] = useState('');

    useEffect(() => {
        const _value = ((value - min) / (max - min)) * 100;

        setBackground(
            'linear-gradient(to right, #116dff 0%, #116dff ' + _value + '%, #0006244d ' + _value + '%, #0006244d 100%)'
        );
    }, [value]);

    const handleChange = event => {
        const eventValue = event.target.value;

        setValue(eventValue);
    };

    return (
        <div className="slider" style={style}>
            <div className="title">{title}</div>
            <div className="slider-container">
                <input
                    className="slider-input"
                    style={{ background }}
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                    onChange={handleChange}
                />
                <div className="value">
                    {value} {valueSuffix}
                </div>
            </div>
        </div>
    );
}

export default Slider;
