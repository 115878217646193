import { configureStore } from '@reduxjs/toolkit';
import userReducer from '@/store/slices/userSlice';
import postsReducer from '@/store/slices/postsSlice';
import settingsReducer from '@/store/slices/settingsSlice';

export default configureStore({
    reducer: {
        user: userReducer,
        posts: postsReducer,
        settings: settingsReducer
    }
});
