import watermark from '@/assets/images/watermark.svg';

import '@/assets/styles/media_thumbnail.scss';

function MediaThumbnail({ post, ratio, cornerRadius, itemWidth = '100%', showWatermark, openBehavior, setPostToOpen }) {
    const { thumbnail_url, media_url, permalink } = post;

    const handlePostClick = () => {
        if (openBehavior === 'fullScreen') {
            setPostToOpen(post);
        }

        if (openBehavior === 'instagram') {
            window.open(permalink);
        }
    };

    return (
        <div id="thumbnail" className="thumbnail" onClick={handlePostClick}>
            <div className="thumbnail__image">
                <img
                    style={{ aspectRatio: ratio, borderRadius: +cornerRadius, width: itemWidth }}
                    src={thumbnail_url || media_url}
                    alt="thumbnail"
                />
            </div>
            {!!showWatermark && <img className="thumbnail__watermark" src={watermark} alt="PURPLE watermark" />}
        </div>
    );
}

export default MediaThumbnail;
