import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SinglePost from '@/components/single-post/Index';

import '@/assets/styles/feed.scss';

import { me } from '@/store/slices/userSlice';
import { setPostToOpen } from '@/store/slices/postsSlice';

function SinglePostView() {
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    const { posts, profile } = useSelector(state => state.user);
    const { postToOpen } = useSelector(state => state.posts);

    const [pageId, setPageId] = useState('');

    Wix.getCurrentPageId(id => {
        setPageId(id);
    });

    useEffect(() => {
        if (!pageId) {
            return;
        }

        dispatch(me(pageId));
    }, [pageId]);

    useEffect(() => {
        if (!posts.length) {
            return;
        }

        const index = searchParams.get('index');

        dispatch(setPostToOpen(posts[index]));
    }, [posts]);

    const openPost = post => dispatch(setPostToOpen(post));

    return (
        <SinglePost
            post={postToOpen}
            posts={posts}
            username={profile?.username}
            avatarUrl={profile?.avatar}
            openPost={openPost}
        />
    );
}

export default SinglePostView;
