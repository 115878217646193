import '@/assets/styles/dropdown.scss';

function Dropdown({ options, value, label, style, onSelect }) {
    return (
        <div className={'dropdown'} style={style}>
            {!!label && <label htmlFor="font">{label}</label>}
            <select
                value={value}
                className="dropdown__input"
                name="font"
                id="font"
                onChange={e => onSelect(options[e.target.value].value)}
            >
                {options.map((option, index) => (
                    <option key={option.label} value={index}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Dropdown;
