import grid from '@/assets/images/grid.svg';
import gridActive from '@/assets/images/grid_active.svg';
import slider from '@/assets/images/slider.svg';
import sliderActive from '@/assets/images/slider_active.svg';
import collage from '@/assets/images/collage.svg';
import collageActive from '@/assets/images/collage_active.svg';

import ratio1x1 from '@/assets/images/image_ratio_1x1.svg';
import ratio1x1Active from '@/assets/images/image_ratio_1x1_active.svg';
import ratio16x9 from '@/assets/images/image_ratio_16x9.svg';
import ratio16x9Active from '@/assets/images/image_ratio_16x9_active.svg';
import ratio4x3 from '@/assets/images/image_ratio_4x3.svg';
import ratio4x3Active from '@/assets/images/image_ratio_4x3_active.svg';
import ratio3x4 from '@/assets/images/image_ratio_3x4.svg';
import ratio3x4Active from '@/assets/images/image_ratio_3x4_active.svg';
import ratio9x16 from '@/assets/images/image_ratio_9x16.svg';
import ratio9x16Active from '@/assets/images/image_ratio_9x16_active.svg';

import ltr from '@/assets/images/ltr.svg';
import ltrActive from '@/assets/images/ltr_active.svg';
import rtl from '@/assets/images/rtl.svg';
import rtlActive from '@/assets/images/rtl_active.svg';

import '@/assets/styles/image_button.scss';

const images = {
    grid,
    gridActive,
    slider,
    sliderActive,
    collage,
    collageActive,
    ratio1x1,
    ratio1x1Active,
    ratio16x9,
    ratio16x9Active,
    ratio4x3,
    ratio4x3Active,
    ratio3x4,
    ratio3x4Active,
    ratio9x16,
    ratio9x16Active,
    ltr,
    ltrActive,
    rtl,
    rtlActive
};

function ImageButton({ name, title, isActive, isSmall, setValue }) {
    return (
        <div className="image-button" onClick={() => setValue(name)}>
            <img
                className="image-button__image"
                style={isSmall && { width: 43 }}
                src={isActive ? images[`${name}Active`] : images[name]}
                alt="button"
            />
            <span className="image-button__title" style={{ color: isActive ? '#116DFF' : '#000' }}>
                {title ? title : name}
            </span>
        </div>
    );
}

export default ImageButton;
