import { useState } from 'react';

import getDate from '@/helpers/getDate';

import '@/assets/styles/comment.scss';

function Comment({ comment }) {
    const { author, content, date, likes, replies } = comment;

    const [showReplies, setShowReplies] = useState(false);

    return (
        <div className="comment">
            <div className="comment__top-row">
                <div>
                    <span className="comment__author">{author}</span>
                    {content}
                </div>
            </div>
            <div className="comment__bottom-row">
                <div className="comment__date">{getDate(date)}</div>
                <div>
                    {likes} {likes === 1 ? 'like' : 'likes'}
                </div>
            </div>
            <div className="replies" onClick={() => setShowReplies(!showReplies)}>
                {!!replies.length && (
                    <div className="replies-text">
                        {showReplies ? 'Hide replies' : `View replies (${replies.length})`}
                    </div>
                )}
                {!!showReplies &&
                    replies.map((reply, index) => (
                        <div key={index} className="comment__reply">
                            <div className="comment__top-row">
                                <div>
                                    <span className="comment__author">{reply.author}</span>
                                    {reply.content}
                                </div>
                            </div>
                            <div className="comment__bottom-row">
                                <div className="comment__date">{getDate(reply.date)}</div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default Comment;
