import { useSelector } from 'react-redux';

import Slider from '@/components/settings/Slider';
import RadioInput from '@/components/settings/RadioInput';
import ImageButton from '@/components/settings/ImageButton';
import PlatformSwitcher from '@/components/settings/PlatformSwitcher';

import '@/assets/styles/settings.scss';

// @TODO Add collage layout
const layouts = ['grid', 'slider'];
const ratios = ['ratio1x1', 'ratio16x9', 'ratio4x3', 'ratio3x4', 'ratio9x16'];
const directions = ['ltr', 'rtl'];

function Layout({ deviceType, settings, setSettings, changeDeviceType }) {
    const { premiumTier } = useSelector(state => state.user);

    const setValue = (name, value) => {
        setSettings({ ...settings, [name]: value });
    };

    return (
        <>
            <PlatformSwitcher deviceType={deviceType} changeDeviceType={changeDeviceType} />
            <div className="card">
                <span className="card__title">Post Layout</span>
                <div className="card__buttons">
                    {layouts.map(layout => (
                        <ImageButton
                            key={layout}
                            name={layout}
                            isActive={settings.layout === layout}
                            setValue={value => setValue('layout', value)}
                        />
                    ))}
                </div>
            </div>
            <div className="card">
                <span className="card__title">Image Ratio</span>
                <div className="card__buttons">
                    {ratios.map(ratio => (
                        <ImageButton
                            key={ratio}
                            name={ratio}
                            title={ratio.replace('ratio', '')}
                            isSmall
                            isActive={settings.ratio === ratio}
                            setValue={value => setValue('ratio', value)}
                        />
                    ))}
                </div>
            </div>
            <div className="card">
                <span className="card__title">Direction</span>
                <div className="card__buttons">
                    {directions.map(direction => (
                        <ImageButton
                            key={direction}
                            name={direction}
                            title={direction === 'ltr' ? 'Left to right' : 'Right to left'}
                            isActive={settings.direction === direction}
                            setValue={value => setValue('direction', value)}
                        />
                    ))}
                </div>
            </div>
            <div className="card">
                {settings.layout !== 'slider' && (
                    <Slider
                        title="Number of Rows"
                        min={1}
                        max={10}
                        value={settings.rows}
                        deviceType={deviceType}
                        setValue={value => setValue('rows', value)}
                    />
                )}
                <Slider
                    title="Number of Columns"
                    min={1}
                    max={10}
                    value={settings.columns}
                    deviceType={deviceType}
                    setValue={value => setValue('columns', value)}
                />
                <Slider
                    title="Spacing"
                    min={0}
                    max={100}
                    valueSuffix=" px"
                    value={settings.spacing}
                    style={{ marginBottom: 0 }}
                    deviceType={deviceType}
                    setValue={value => setValue('spacing', value)}
                />
            </div>
            <div className="card">
                <span className="card__title">Number of Posts</span>
                <RadioInput
                    name="20"
                    value={settings.posts}
                    title="Up to 20 Posts"
                    setValue={value => setValue('posts', value)}
                />
                <RadioInput
                    name="50"
                    value={settings.posts}
                    title="Up to 50 Posts"
                    isLocked={premiumTier < 1}
                    setValue={value => setValue('posts', value)}
                />
                <RadioInput
                    name="100"
                    value={settings.posts}
                    title="Up to 100 Posts"
                    isLocked={premiumTier < 2}
                    setValue={value => setValue('posts', value)}
                />
                <RadioInput
                    name="unlimited"
                    value={settings.posts}
                    title="Unlimited"
                    style={{ marginBottom: 0 }}
                    isLocked={premiumTier < 3}
                    setValue={value => setValue('posts', value)}
                />
            </div>
        </>
    );
}

export default Layout;
