import { useState, useEffect } from 'react';
import { useImageSize } from 'react-image-size';
import { useSwipeable } from 'react-swipeable';

import getDate from '@/helpers/getDate';

import Comment from '@/components/single-post/Comment';

import arrow from '@/assets/images/arrow.svg';
import close from '@/assets/images/close.svg';
import watermark from '@/assets/images/watermark.svg';

import '@/assets/styles/single_post.scss';

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

function SinglePost({ post, username, avatarUrl, posts, openPost }) {
    const [imageHeight, setImageHeight] = useState();
    const [dimensions] = useImageSize(post?.media_url);

    const handlers = useSwipeable({
        onSwipedLeft: ({ deltaX }) => {
            if (deltaX < -50) {
                openNextPost();
            }
        },
        onSwipedRight: ({ deltaX }) => {
            if (deltaX > 50) {
                openPreviousPost();
            }
        }
    });

    useEffect(() => {
        if (!post || !dimensions) {
            return;
        }

        const { width, height } = dimensions;

        setImageHeight((height / width) * 600);
    });

    if (!post) {
        return;
    }

    const { media_url, media_type, date, description, comments, likes } = post;

    const closePost = event => {
        if (event.target !== event.currentTarget) {
            return;
        }

        Wix.closeWindow();
    };

    const openNextPost = () => {
        const nextPost = posts.find(item => item.index === post.index + 1);

        if (!nextPost) {
            return;
        }

        openPost(nextPost);
    };

    const openPreviousPost = () => {
        const previousPost = posts.find(item => item.index === post.index - 1);

        if (!previousPost) {
            return;
        }

        openPost(previousPost);
    };

    return (
        <div className="modal-container" {...handlers} onClick={closePost}>
            <img className="close" src={close} alt="close" onClick={closePost} />
            {post.index !== 0 && (
                <img className="arrow arrow-left" src={arrow} alt="arrow" onClick={openPreviousPost} />
            )}
            <div className="sidebar__header__mobile">
                <img className="sidebar__avatar" src={avatarUrl} alt="avatar" />
                <div>
                    <div className="sidebar__username">{username}</div>
                    <div className="sidebar__date">{getDate(date)}</div>
                </div>
            </div>
            <div style={{ height: imageHeight }} className="post-container">
                {media_type === 'IMAGE' ? (
                    <>
                        <div className="media-container">
                            <img className="media" src={media_url} alt="media" />
                            <img className="watermark" src={watermark} alt="PURPLE watermark" />
                        </div>
                    </>
                ) : (
                    <video controls>
                        <source src={media_url} />
                    </video>
                )}
                <div className="sidebar">
                    <div className="sidebar__header">
                        {avatarUrl && <img className="sidebar__avatar" src={avatarUrl} alt="avatar" />}
                        <div>
                            <div className="sidebar__username">{username}</div>
                            {post.date && <div className="sidebar__date">{getDate(date)}</div>}
                        </div>
                    </div>
                    {post.comments && (
                        <div className="sidebar__content">
                            <div className="sidebar__description">{description}</div>
                            <div className="sidebar__comments">
                                {!!comments?.length &&
                                    comments?.map((comment, index) => <Comment key={index} comment={comment} />)}
                            </div>
                        </div>
                    )}
                    {post.likes && (
                        <div className="sidebar__footer">
                            <div>
                                {formatter.format(likes)} {likes === 1 ? 'like' : 'likes'}
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                {formatter.format(comments?.length)} {comments?.length === 1 ? 'comment' : 'comments'}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {post.index !== posts.length - 1 && (
                <img className="arrow arrow-right" src={arrow} alt="arrow" onClick={openNextPost} />
            )}
        </div>
    );
}

export default SinglePost;
