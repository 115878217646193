import '@/assets/styles/header.scss';

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

function Header({
    avatarUrl,
    username,
    posts,
    followers,
    following,
    descriptionTitle,
    description,
    layoutSettings,
    displaySettings,
    designSettings,
    isBusiness
}) {
    return (
        <div
            className="header"
            style={{
                fontSize: +designSettings.bioFontSize,
                color: designSettings.textColor
            }}
        >
            {isBusiness && displaySettings.profileImage && (
                <img
                    className="header__avatar"
                    style={layoutSettings.direction === 'rtl' ? { marginLeft: 22 } : { marginRight: 22 }}
                    src={avatarUrl}
                    alt="avatar"
                />
            )}
            <div className="header__data-container">
                <div className="header__first-row">
                    {displaySettings.userName && (
                        <div className="header__username" style={{ fontSize: +designSettings.usernameFontSize }}>
                            {username}
                        </div>
                    )}
                    {isBusiness && displaySettings.followButton && (
                        <button
                            className="header__follow apply-font"
                            style={{
                                backgroundColor: designSettings.buttonsColor,
                                color: designSettings.buttonsTextColor,
                                marginLeft: layoutSettings.direction === 'rtl' ? 0 : 16,
                                marginRight: layoutSettings.direction === 'rtl' ? 16 : 0
                            }}
                        >
                            Follow
                        </button>
                    )}
                </div>
                <div className="header__second-row">
                    {displaySettings.numberOfPosts && (
                        <div
                            className="header__count"
                            style={layoutSettings.direction === 'rtl' ? { marginLeft: 60 } : { marginRight: 60 }}
                        >
                            <span>
                                {formatter.format(posts)} {posts === 1 ? 'post' : 'posts'}
                            </span>
                        </div>
                    )}
                    {isBusiness && displaySettings.followers && (
                        <div
                            className="header__count"
                            style={layoutSettings.direction === 'rtl' ? { marginLeft: 60 } : { marginRight: 60 }}
                        >
                            {formatter.format(followers)} {followers === 1 ? 'follower' : 'followers'}
                        </div>
                    )}
                    {isBusiness && displaySettings.following && <div>{formatter.format(following)} following</div>}
                </div>
                {isBusiness && displaySettings.bio && (
                    <>
                        <div className="header__third-row">{descriptionTitle}</div>
                        <div className="header__fourth-row">{description}</div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Header;
