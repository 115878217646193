import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Dropdown from '@/components/settings/Dropdown';
import RadioInput from '@/components/settings/RadioInput';
import PlatformSwitcher from '@/components/settings/PlatformSwitcher';

import '@/assets/styles/settings.scss';

function General({
    deviceType,
    settings,
    instagramDataRefreshRate = '24h',
    setSettings,
    changeDeviceType,
    changeInstagramDataRefreshRate
}) {
    const { premiumTier } = useSelector(state => state.user);

    const [refreshRate, setRefreshRate] = useState(instagramDataRefreshRate);

    useEffect(() => {
        setRefreshRate(instagramDataRefreshRate);
    }, [instagramDataRefreshRate]);

    const setValue = (name, value) => {
        setSettings({ ...settings, [name]: value });
    };

    const setInstagramDataRefreshRate = value => {
        setRefreshRate(value);
        changeInstagramDataRefreshRate(value);
    };

    return (
        <>
            <PlatformSwitcher deviceType={deviceType} changeDeviceType={changeDeviceType} />
            <div className="card">
                <span className="card__title">Update Frequency</span>
                <RadioInput
                    name="24h"
                    value={refreshRate}
                    title="Daily"
                    setValue={() => setInstagramDataRefreshRate('24h')}
                />
                <RadioInput
                    name="12h"
                    value={refreshRate}
                    title="Every 12 Hours"
                    isLocked={premiumTier < 1}
                    setValue={() => setInstagramDataRefreshRate('12h')}
                />
                <RadioInput
                    name="6h"
                    value={refreshRate}
                    title="Every 6 Hours"
                    isLocked={premiumTier < 2}
                    setValue={() => setInstagramDataRefreshRate('6h')}
                />
                <RadioInput
                    name="10m"
                    value={refreshRate}
                    title="Real Time Updates"
                    style={{ marginBottom: 0 }}
                    isLocked={premiumTier < 3}
                    setValue={() => setInstagramDataRefreshRate('10m')}
                />
            </div>
            <div className="card">
                <span className="card__title">Open Links</span>
                <RadioInput
                    name="instagram"
                    value={settings.openLinks}
                    title="Open on Instagram"
                    setValue={() => setValue('openLinks', 'instagram')}
                />
                <RadioInput
                    name="fullScreen"
                    value={settings.openLinks}
                    title="Expand to Full Screen"
                    setValue={() => setValue('openLinks', 'fullScreen')}
                />
                <RadioInput
                    name="nothing"
                    value={settings.openLinks}
                    title="Nothing Happens"
                    setValue={() => setValue('openLinks', 'nothing')}
                />
            </div>
            <div className="card">
                <span className="card__title">App Language</span>
                <Dropdown options={[{ value: 'english', label: 'English' }]} />
            </div>
        </>
    );
}

export default General;
