import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import store from '@/store';

import Auth from '@/pages/Auth';
import Feed from '@/pages/Feed';
import Settings from '@/pages/Settings';
import SinglePost from '@/pages/SinglePost';

import '@/assets/styles/index.scss';

require('./bootstrap/axios.config');

const router = createBrowserRouter([
    {
        path: '/feed',
        element: <Feed />
    },
    {
        path: '/single-post',
        element: <SinglePost />
    },
    {
        path: '/settings',
        element: <Settings />
    },
    {
        path: '/auth',
        element: <Auth />
    }
]);

const container = document.getElementById('root');

createRoot(container).render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
);
