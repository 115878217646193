import '@/assets/styles/platform_switcher.scss';

function PlatformSwitcher({ deviceType, changeDeviceType }) {
    return (
        <div className="platform-switcher">
            <span
                className={`platform ${deviceType === 'desktop' && 'selected'}`}
                onClick={() => changeDeviceType('desktop')}
            >
                Desktop
            </span>
            <span
                className={`platform ${deviceType === 'mobile' && 'selected'}`}
                onClick={() => changeDeviceType('mobile')}
            >
                Mobile
            </span>
        </div>
    );
}

export default PlatformSwitcher;
