import premiumBlue from '@/assets/images/premium_blue.svg';

import '@/assets/styles/radio_input.scss';

function RadioInput({ name, value, title, isLocked, style, setValue }) {
    return (
        <div className="radio-input" style={style}>
            <div className="radio-container">
                <input
                    type="radio"
                    value={value}
                    name={name}
                    checked={value === name}
                    disabled={isLocked}
                    onChange={() => setValue(name)}
                />
                <label style={isLocked ? { opacity: 0.5 } : {}} htmlFor={name}>
                    {title}
                </label>
            </div>
            {!!isLocked && <img className="premium-icon" src={premiumBlue} alt="premium" />}
        </div>
    );
}

export default RadioInput;
