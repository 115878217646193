import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const getDate = timestamp => {
    const date = dayjs(timestamp);
    const now = dayjs();

    if (now.diff(date, 'day') < 4) {
        return date.fromNow();
    }

    return date.format('MMMM DD, YYYY');
};

export default getDate;
