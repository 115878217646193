import { useSelector } from 'react-redux';

import Checkbox from '@/components/settings/Checkbox';
import PlatformSwitcher from '@/components/settings/PlatformSwitcher';

import '@/assets/styles/settings.scss';

function Display({ deviceType, settings, setSettings, changeDeviceType }) {
    const { premiumTier } = useSelector(state => state.user);

    const setValue = name => {
        setSettings({ ...settings, [name]: !settings[name] });
    };

    return (
        <>
            <PlatformSwitcher deviceType={deviceType} changeDeviceType={changeDeviceType} />
            <div className="card">
                <span className="card__title">PURPLE Watermark</span>
                <Checkbox
                    value={settings.removeWatermark}
                    title="Remove Watermark"
                    isLocked={premiumTier < 1}
                    setValue={() => setValue('removeWatermark')}
                />
            </div>
            <div className="card">
                <span className="card__title">Instagram Display</span>
                <Checkbox
                    value={settings.profileImage}
                    title="Profile Image"
                    setValue={() => setValue('profileImage')}
                />
                <Checkbox value={settings.userName} title="User Name" setValue={() => setValue('userName')} />
                <Checkbox
                    value={settings.followButton}
                    title="Follow Button"
                    setValue={() => setValue('followButton')}
                />
                <Checkbox
                    value={settings.numberOfPosts}
                    title="Number of Posts"
                    setValue={() => setValue('numberOfPosts')}
                />
                <Checkbox value={settings.followers} title="Followers" setValue={() => setValue('followers')} />
                <Checkbox value={settings.following} title="Following" setValue={() => setValue('following')} />
                <Checkbox value={settings.bio} title="Bio" setValue={() => setValue('bio')} />
                <Checkbox value={settings.loadMore} title='"Load More" Button' setValue={() => setValue('loadMore')} />
            </div>
        </>
    );
}

export default Display;
