import premiumBlue from '@/assets/images/premium_blue.svg';

import '@/assets/styles/checkbox.scss';

function Checkbox({ value, title, isLocked, setValue }) {
    return (
        <div className="checkbox-container">
            <span className="title" style={isLocked ? { opacity: 0.5 } : {}}>
                {title}
            </span>
            <div className="switch-container">
                {!!isLocked && <img className="premium-icon" src={premiumBlue} alt="premium" />}
                <label className="switch">
                    <input type="checkbox" checked={value} disabled={isLocked} onChange={setValue} />
                    <span className={`slider ${!value && 'off'}`} />
                </label>
            </div>
        </div>
    );
}

export default Checkbox;
