import axios from 'axios';
import config from '@/config';

axios.defaults.baseURL = config.apiUrl;
axios.defaults.withCredentials = true;

for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    axios['$' + method] = function () {
        return this[method].apply(this, arguments).then(res => res && res.data);
    };
}

export default axios;
