import { useState, useEffect } from 'react';

import MediaThumbnail from '@/components/feed/MediaThumbnail';

import arrow from '@/assets/images/arrow.svg';

import '@/assets/styles/carousel.scss';

function Carousel({ posts, layoutSettings, designSettings, displaySettings, openBehavior, openPost }) {
    const [carouselWidth, setCarouselWidth] = useState(0);

    useEffect(() => {
        const prev = document.querySelector('.prev');
        const next = document.querySelector('.next');
        const carousel = document.querySelector('.carousel-container');
        const track = document.querySelector('.track');
        let width = carousel.offsetWidth;
        let _index = 0;

        setCarouselWidth(width);

        window.addEventListener('resize', function () {
            width = carousel.offsetWidth;
        });

        next.addEventListener('click', function (e) {
            e.preventDefault();

            if (layoutSettings.direction === 'ltr') {
                _index = _index + 1;
            } else {
                _index = _index - 1;
            }

            prev.classList.add('show');

            track.style.transform = 'translateX(' + _index * -width + 'px)';

            if (layoutSettings.direction === 'ltr' && track.offsetWidth - _index * width < _index * width) {
                next.classList.add('hide');
            } else if (layoutSettings.direction === 'rtl' && track.offsetWidth - -_index * width < -_index * width) {
                next.classList.add('hide');
            }
        });

        prev.addEventListener('click', function (e) {
            if (layoutSettings.direction === 'ltr') {
                _index = _index - 1;
            } else {
                _index = _index + 1;
            }

            next.classList.remove('hide');

            if (_index === 0) {
                prev.classList.remove('show');
            }

            track.style.transform = 'translateX(' + _index * -width + 'px)';
        });
    }, [layoutSettings.direction]);

    return (
        <div className="carousel-container" style={{ direction: layoutSettings.direction }}>
            <div className="inner-carousel">
                <div className="track">
                    {posts.map((post, index) => (
                        <div
                            key={index}
                            style={{ marginLeft: layoutSettings.spacing / 2, marginRight: layoutSettings.spacing / 2 }}
                        >
                            <MediaThumbnail
                                post={post}
                                ratio={layoutSettings.ratio}
                                cornerRadius={designSettings.cornerRadius}
                                itemWidth={carouselWidth / layoutSettings.columns - layoutSettings.spacing}
                                openBehavior={openBehavior}
                                showWatermark={!displaySettings.removeWatermark}
                                setPostToOpen={openPost}
                            />
                        </div>
                    ))}
                </div>
                <div className="nav">
                    <button
                        className="next"
                        style={
                            layoutSettings.direction === 'ltr'
                                ? { right: -30, display: posts.length <= layoutSettings.columns ? 'none' : 'block' }
                                : { left: -30, display: posts.length <= layoutSettings.columns ? 'none' : 'block' }
                        }
                    >
                        <img
                            src={arrow}
                            className={layoutSettings.direction === 'ltr' ? 'arrow-right' : 'arrow-left'}
                            alt="arrow"
                        />
                    </button>
                    <button
                        className="prev"
                        style={layoutSettings.direction === 'ltr' ? { left: -30 } : { right: -30 }}
                    >
                        <img
                            src={arrow}
                            className={layoutSettings.direction === 'ltr' ? 'arrow-left' : 'arrow-right'}
                            alt="arrow"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Carousel;
