import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import FontPicker from 'font-picker';

import Slider from '@/components/settings/Slider';
import Dropdown from '@/components/settings/Dropdown';
import PlatformSwitcher from '@/components/settings/PlatformSwitcher';

import close from '@/assets/images/close.svg';
import premiumBlue from '@/assets/images/premium_blue.svg';

import '@/assets/styles/settings.scss';

const { REACT_APP_GOOGLE_FONT_API_KEY } = process.env;

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
};

function Design({ deviceType, settings, setSettings, changeDeviceType }) {
    const { premiumTier } = useSelector(state => state.user);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedColorType, setSelectedColorType] = useState('');
    const [fonts, setFonts] = useState([]);
    const [fontPicker, setFontPicker] = useState();

    useEffect(() => {
        const _fontPicker = new FontPicker(REACT_APP_GOOGLE_FONT_API_KEY, 'Open Sans', { limit: 100 });

        setFontPicker(_fontPicker);

        setTimeout(() => {
            _fontPicker.setActiveFont(settings.font.family);

            setFonts(
                Array.from(_fontPicker.getFonts(), ([key, value]) => ({ label: key, value })).sort((fontA, fontB) =>
                    fontA.label < fontB.label ? -1 : fontA.label > fontB.label ? 1 : 0
                )
            );
        }, 100);
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = type => {
        setSelectedColorType(type);
        setIsModalOpen(true);
    };

    const setColor = newColor => {
        setSettings({
            ...settings,
            [selectedColorType]: newColor
        });
    };

    const setFont = font => {
        setValue('font', font);

        fontPicker.setActiveFont(font.family);
    };

    const setValue = (name, value) => {
        setSettings({ ...settings, [name]: value });
    };

    const handleCssInputChange = event => {
        setValue('customCSS', event.target.value);
    };

    return (
        <>
            <PlatformSwitcher deviceType={deviceType} changeDeviceType={changeDeviceType} />
            <div className="card">
                <span className="card__title">Font</span>
                <div id="font-picker" style={{ display: 'none' }} />
                <Dropdown
                    options={fonts}
                    value={fonts.findIndex(item => item.label === settings.font.family)}
                    label="Font"
                    style={{ marginBottom: 10 }}
                    onSelect={setFont}
                />
                <Slider
                    title="Username Font size"
                    min={10}
                    max={40}
                    value={settings.usernameFontSize}
                    valueSuffix=" px"
                    deviceType={deviceType}
                    style={{ marginBottom: 0, fontWeight: 'normal' }}
                    setValue={value => setValue('usernameFontSize', value)}
                />
                <Slider
                    title="Bio Font Size"
                    min={10}
                    max={40}
                    value={settings.bioFontSize}
                    valueSuffix=" px"
                    deviceType={deviceType}
                    style={{ marginBottom: 0, fontWeight: 'normal' }}
                    setValue={value => setValue('bioFontSize', value)}
                />
            </div>
            <div className="card">
                <span className="card__title">Colors</span>
                <div className="color-sample-container">
                    Text{' '}
                    <div
                        className="color-sample"
                        style={{ backgroundColor: settings.textColor }}
                        onClick={() => openModal('textColor')}
                    />
                </div>
                <div className="color-sample-container">
                    Background{' '}
                    <div
                        className="color-sample"
                        style={{ backgroundColor: settings.backgroundColor }}
                        onClick={() => openModal('backgroundColor')}
                    />
                </div>
                <div className="color-sample-container">
                    Buttons{' '}
                    <div
                        className="color-sample"
                        style={{ backgroundColor: settings.buttonsColor }}
                        onClick={() => openModal('buttonsColor')}
                    />
                </div>
                <div className="color-sample-container" style={{ marginBottom: 0 }}>
                    Buttons Text{' '}
                    <div
                        className="color-sample"
                        style={{ backgroundColor: settings.buttonsTextColor }}
                        onClick={() => openModal('buttonsTextColor')}
                    />
                </div>
                <Modal isOpen={isModalOpen} style={modalStyles} onRequestClose={closeModal}>
                    <img className="close" src={close} alt="close" onClick={closeModal} />
                    <HexColorPicker
                        style={{ margin: 'auto', top: 0 }}
                        color={settings[selectedColorType]}
                        onChange={setColor}
                    />
                    <HexColorInput className="color-input" color={settings[selectedColorType]} onChange={setColor} />
                </Modal>
            </div>
            <div className="card">
                <Slider
                    title="Corner radius"
                    min={0}
                    max={50}
                    value={settings.cornerRadius}
                    valueSuffix=" px"
                    style={{ marginBottom: 0 }}
                    deviceType={deviceType}
                    setValue={value => setValue('cornerRadius', value)}
                />
            </div>
            <div className="card ">
                <span className="card__title">
                    Custom CSS <img className="premium-icon" src={premiumBlue} alt="premium" />
                </span>
                <textarea
                    disabled={premiumTier < 1}
                    className="card__css"
                    rows="8"
                    placeholder="Type here"
                    value={settings.customCSS}
                    onChange={handleCssInputChange}
                />
            </div>
        </>
    );
}

export default Design;
