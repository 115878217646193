import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const personalLogin = createAsyncThunk('auth/personalLogin', async ({ authCode, pageId }) => {
    const { data } = await axios.post('/auth/personal-login', {
        authCode,
        pageId
    });

    return data;
});

export const me = createAsyncThunk('auth/me', async pageId => {
    const { data } = await axios.get(`/auth/me/${pageId}`);

    return data;
});

export const logout = createAsyncThunk('auth/logout', async pageId => {
    return axios.delete(`/auth/logout/${pageId}`);
});

export const updatePremiumTier = createAsyncThunk('user/updatePremiumTier', async ({ pageId, premiumTier }) => {
    const { data } = await axios.put(`/user/${pageId}/premium-tier`, {
        premiumTier
    });

    return data.premiumTier;
});

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        profile: null,
        posts: [],
        premiumTier: null,
        profileType: null
    },
    reducers: {
        setUserData: (state, action) => {
            state.posts = action.payload.posts;
            state.profileType = action.payload.profileType;
            state.profile = action.payload.profile;
        },
        removeUserData: state => {
            state.posts = [];
            state.premiumTier = null;
            state.profileType = null;
            state.profile = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(me.fulfilled, (state, action) => {
                state.posts = action.payload.instagramPosts;
                state.premiumTier = action.payload.premiumTier;
                state.profileType = action.payload.profileType;
                state.profile = action.payload.instagramProfileData;
            })
            .addCase(personalLogin.fulfilled, (state, action) => {
                state.posts = action.payload.instagramPosts;
                state.premiumTier = action.payload.premiumTier;
                state.profileType = action.payload.profileType;
                state.profile = action.payload.instagramProfileData;
            })
            .addCase(updatePremiumTier.fulfilled, (state, action) => {
                state.premiumTier = action.payload;
            })
            .addCase(logout.fulfilled, state => {
                state.posts = [];
                state.premiumTier = null;
                state.profileType = null;
                state.profile = null;
            });
    }
});

export const { setUserData, removeUserData } = userSlice.actions;

export default userSlice.reducer;
