import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Design from '@/components/settings/tabs/Design';
import Layout from '@/components/settings/tabs/Layout';
import Connect from '@/components/settings/tabs/Connect';
import Display from '@/components/settings/tabs/Display';
import General from '@/components/settings/tabs/General';

import premium from '@/assets/images/premium.svg';

import '@/assets/styles/settings.scss';

import { updatePremiumTier } from '@/store/slices/userSlice';
import { saveSettings, getSettings } from '@/store/slices/settingsSlice';

import useFirstRender from '@/helpers/useFirstRender';

const { REACT_APP_WIX_APP_ID } = process.env;

const tabs = ['connect', 'layout', 'display', 'design', 'general'];
const planNameToTier = {
    light: 1,
    core: 2,
    premium: 3
};

function Settings() {
    const dispatch = useDispatch();

    const { premiumTier } = useSelector(state => state.user);
    const { settings } = useSelector(state => state.settings);

    const [pageId, setPageId] = useState('');
    const [appInstanceId, setAppInstanceId] = useState('');
    const [deviceType, setDeviceType] = useState('desktop');
    const [selectedTab, setSelectedTab] = useState('connect');
    const [deviceSettings, setDeviceSettings] = useState(settings[deviceType]);

    Wix.getCurrentPageId(id => {
        setPageId(id);
    });

    useEffect(() => {
        setDeviceType(Wix.Utils.getDeviceType());
        setAppInstanceId(Wix.Utils.getInstanceId());
    }, []);

    useFirstRender(() => {
        const params = new URLSearchParams(document.location.search);
        const [, appData] = params.get('instance').split('.');
        const appInstance = JSON.parse(atob(appData));

        const appInstancePremiumTier = planNameToTier[appInstance.vendorProductId] || 0;

        if (appInstancePremiumTier !== premiumTier) {
            dispatch(updatePremiumTier({ pageId, premiumTier: appInstancePremiumTier }));

            if (appInstancePremiumTier > premiumTier) {
                return;
            }

            degradePremiumSettings(appInstancePremiumTier);
        }
    }, [premiumTier]);

    useEffect(() => {
        if (!pageId) {
            return;
        }

        dispatch(getSettings(pageId));
    }, [pageId, deviceType]);

    useEffect(() => {
        setDeviceSettings(settings[deviceType]);
    }, [settings, deviceType]);

    useEffect(() => {
        Wix.Settings.triggerSettingsUpdatedEvent({
            type: 'SETTINGS',
            value: deviceSettings
        });
    }, [deviceSettings]);

    const changeDeviceType = type => {
        setDeviceType(type);

        Wix.Settings.triggerSettingsUpdatedEvent({
            type: 'DEVICE_TYPE',
            value: type
        });
    };

    const changeSettings = (settingsType, value) => {
        const newSettings = { ...deviceSettings, [settingsType]: value };

        setDeviceSettings({ ...deviceSettings, [settingsType]: value });
        updateSettings(newSettings);
    };

    const updateSettings = newSettings => {
        dispatch(
            saveSettings({
                settings: {
                    ...settings,
                    [deviceType]: newSettings
                },
                pageId
            })
        );
    };

    const changeRefreshRate = value => {
        dispatch(
            saveSettings({
                settings: {
                    ...settings,
                    instagramDataRefreshRate: value
                },
                pageId
            })
        );
    };

    const upgrade = () => {
        window.open(`https://www.wix.com/apps/upgrade/${REACT_APP_WIX_APP_ID}?appInstanceId=${appInstanceId}`);
    };

    const degradePremiumSettings = tier => {
        const _settings = JSON.parse(JSON.stringify(settings));

        if (tier === 2) {
            if (_settings.desktop.numberOfPosts === 'unlimited') {
                _settings.desktop.numberOfPosts = '100';
            }

            if (_settings.mobile.numberOfPosts === 'unlimited') {
                _settings.mobile.numberOfPosts = '100';
            }

            if (_settings.instagramDataRefreshRate === '10m') {
                _settings.instagramDataRefreshRate = '6h';
            }
        } else if (tier === 1) {
            if (_settings.desktop.numberOfPosts === 'unlimited' || _settings.desktop.numberOfPosts === '100') {
                _settings.desktop.numberOfPosts = '50';
            }

            if (_settings.mobile.numberOfPosts === 'unlimited' || _settings.mobile.numberOfPosts === '100') {
                _settings.mobile.numberOfPosts = '50';
            }

            if (_settings.instagramDataRefreshRate === '10m' || _settings.instagramDataRefreshRate === '6h') {
                _settings.instagramDataRefreshRate = '12h';
            }
        } else if (tier === 0) {
            _settings.desktop.numberOfPosts = '20';
            _settings.mobile.numberOfPosts = '20';
            _settings.desktop.removeWatermark = false;
            _settings.mobile.removeWatermark = false;
            _settings.desktop.customCSS = '';
            _settings.mobile.customCSS = '';
            _settings.instagramDataRefreshRate = '24h';
        }

        dispatch(
            saveSettings({
                settings: _settings,
                pageId
            })
        );
    };

    return (
        <div className="settings">
            <div className="settings__section-left">
                <div className="tabs">
                    {tabs.map(tab => (
                        <button
                            key={tab}
                            className="sidebar-button"
                            style={{ backgroundColor: selectedTab === tab ? '#e7f0ff' : '#fff' }}
                            onClick={() => setSelectedTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                    <button className="sidebar-button upgrade-button" onClick={upgrade}>
                        <img className="premium" src={premium} alt="premium" />
                        Upgrade
                    </button>
                </div>
                <span className="support">Support</span>
            </div>
            <div
                className="settings__section-right"
                style={selectedTab === 'connect' ? { justifyContent: 'center' } : { display: 'none' }}
            >
                <Connect />
            </div>
            <div
                className="settings__section-right"
                style={selectedTab === 'layout' ? { justifyContent: 'flex-start' } : { display: 'none' }}
            >
                <Layout
                    deviceType={deviceType}
                    settings={deviceSettings.layout}
                    setSettings={value => changeSettings('layout', value)}
                    changeDeviceType={changeDeviceType}
                />
            </div>
            <div
                className="settings__section-right"
                style={selectedTab === 'display' ? { justifyContent: 'flex-start' } : { display: 'none' }}
            >
                <Display
                    deviceType={deviceType}
                    settings={deviceSettings.display}
                    setSettings={value => changeSettings('display', value)}
                    changeDeviceType={changeDeviceType}
                />
            </div>
            <div
                className="settings__section-right"
                style={selectedTab === 'design' ? { justifyContent: 'flex-start' } : { display: 'none' }}
            >
                <Design
                    deviceType={deviceType}
                    settings={deviceSettings.design}
                    setSettings={value => changeSettings('design', value)}
                    changeDeviceType={changeDeviceType}
                />
            </div>
            <div
                className="settings__section-right"
                style={selectedTab === 'general' ? { justifyContent: 'flex-start' } : { display: 'none' }}
            >
                <General
                    deviceType={deviceType}
                    settings={deviceSettings.general}
                    instagramDataRefreshRate={settings.instagramDataRefreshRate}
                    setSettings={value => changeSettings('general', value)}
                    changeDeviceType={changeDeviceType}
                    changeInstagramDataRefreshRate={changeRefreshRate}
                />
            </div>
        </div>
    );
}

export default Settings;
