import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FontPicker from 'font-picker';

import Header from '@/components/feed/Header';
import Carousel from '@/components/feed/Carousel';
import MediaThumbnail from '@/components/feed/MediaThumbnail';

import '@/assets/styles/feed.scss';

import { setPostToOpen } from '@/store/slices/postsSlice';
import { getSettings, saveSettings } from '@/store/slices/settingsSlice';
import { me, setUserData, updatePremiumTier, removeUserData } from '@/store/slices/userSlice';

import useFirstRender from '@/helpers/useFirstRender';

const { REACT_APP_GOOGLE_FONT_API_KEY } = process.env;

const { location } = window;
const url = location.protocol + '//' + location.host;

const planNameToTier = {
    light: 1,
    core: 2,
    premium: 3
};

const mountStyles = stylesString => {
    const styleTag = document.getElementById('custom-css') || document.createElement('style');

    styleTag.id = 'custom-css';
    styleTag.textContent = stylesString;

    if (stylesString) {
        document.head.appendChild(styleTag);
    } else {
        styleTag.remove();
    }
};

// @TODO add some placeholder posts when user is not logged in
function Feed() {
    const { settings } = useSelector(state => state.settings);
    const { posts, profile, premiumTier, profileType } = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [pageId, setPageId] = useState('');
    const [deviceType, setDeviceType] = useState('desktop');
    const [postsToDisplay, setPostsToDisplay] = useState(posts);
    const [layoutSettings, setLayoutSettings] = useState(settings[deviceType].layout);
    const [displaySettings, setDisplaySettings] = useState(settings[deviceType].display);
    const [designSettings, setDesignSettings] = useState(settings[deviceType].design);
    const [displayedRows, setDisplayedRows] = useState(layoutSettings.rows);

    const mainRef = useRef();
    const fontPickerRef = useRef();

    Wix.getCurrentPageId(id => setPageId(id));

    useEffect(() => {
        if (!pageId) {
            return;
        }

        dispatch(me(pageId));
        dispatch(getSettings(pageId));
    }, [pageId]);

    useEffect(() => {
        setLayoutSettings({
            ...settings[deviceType].layout,
            ratio: transformRatioSetting(settings[deviceType].layout.ratio)
        });
        setDisplaySettings(settings[deviceType].display);
        setDesignSettings(settings[deviceType].design);

        if (!fontPickerRef.current || fontPickerRef.current?.getFonts().size < 2) {
            return;
        }

        fontPickerRef.current.setActiveFont(settings[deviceType].design.font.family);
    }, [settings]);

    useFirstRender(() => {
        const params = new URLSearchParams(document.location.search);
        const [, appData] = params.get('instance').split('.');
        const appInstance = JSON.parse(atob(appData));

        const appInstancePremiumTier = planNameToTier[appInstance.vendorProductId] || 0;

        if (appInstancePremiumTier !== premiumTier) {
            dispatch(updatePremiumTier({ pageId, premiumTier: appInstancePremiumTier }));

            if (appInstancePremiumTier > premiumTier) {
                return;
            }

            degradePremiumSettings(appInstancePremiumTier);
        }
    }, [premiumTier]);

    useEffect(() => {
        const { customCSS } = designSettings;

        mountStyles(customCSS);
    }, [designSettings]);

    useEffect(() => {
        const fontPicker = new FontPicker(REACT_APP_GOOGLE_FONT_API_KEY, 'Open Sans', { limit: 100 });

        fontPickerRef.current = fontPicker;

        setDeviceType(Wix.Utils.getDeviceType());

        Wix.addEventListener('SETTINGS_UPDATED', message => {
            if (!message.type) {
                return;
            }

            if (message.type === 'CONNECT') {
                dispatch(setUserData(message.userData));
            }

            if (message.type === 'DISCONNECT') {
                dispatch(removeUserData());
            }

            if (message.type === 'DEVICE_TYPE') {
                setDeviceType(message.value);
            }

            if (message.type === 'SETTINGS') {
                setLayoutSettings({
                    ...message.value.layout,
                    ratio: transformRatioSetting(message.value.layout.ratio)
                });
                setDisplaySettings(message.value.display);
                setDesignSettings(message.value.design);

                if (fontPicker) {
                    fontPicker.setActiveFont(message.value.design.font.family);
                }
            }
        });
    }, []);

    useEffect(() => {
        const { rows, columns } = layoutSettings;

        setDisplayedRows(rows);
        setPostsToDisplay(
            posts
                .toReversed()
                .slice(-rows * columns)
                .toReversed()
        );
    }, [posts, layoutSettings.rows, layoutSettings.columns]);

    useEffect(() => {
        setPostsToDisplay(
            posts
                .toReversed()
                .slice(-displayedRows * layoutSettings.columns)
                .toReversed()
        );
    }, [displayedRows]);

    const openPost = post => {
        dispatch(setPostToOpen(post));

        Wix.openModal(`${url}/single-post?index=${post.index}`, 9000, 9000, () => {}, Wix.Theme.BARE);
    };

    const showMore = () => {
        const currentlyDisplaying = layoutSettings.columns * displayedRows;
        const totalPosts = posts.length;
        const postsLeft = totalPosts - currentlyDisplaying;
        const defaultPostsNumber = layoutSettings.columns * layoutSettings.rows;
        const thumbnailHeight = document.getElementById('thumbnail').offsetHeight;

        let rowsToAdd;

        if (postsLeft >= defaultPostsNumber) {
            rowsToAdd = +layoutSettings.rows;
        } else {
            rowsToAdd = Math.ceil(postsLeft / layoutSettings.rows);
        }

        setDisplayedRows(+displayedRows + rowsToAdd);

        Wix.setHeight(window.innerHeight + thumbnailHeight * rowsToAdd);
    };

    const transformRatioSetting = value => {
        switch (value) {
            case 'ratio1x1':
                return '1 / 1';
            case 'ratio16x9':
                return '16 / 9';

            case 'ratio4x3':
                return '4 / 3';

            case 'ratio3x4':
                return '3 / 4';

            case 'ratio9x16':
                return '9 / 16';

            default:
                return '1 / 1';
        }
    };

    const degradePremiumSettings = tier => {
        const _settings = JSON.parse(JSON.stringify(settings));

        if (tier === 2) {
            if (_settings.desktop.numberOfPosts === 'unlimited') {
                _settings.desktop.numberOfPosts = '100';
            }

            if (_settings.mobile.numberOfPosts === 'unlimited') {
                _settings.mobile.numberOfPosts = '100';
            }

            if (_settings.instagramDataRefreshRate === '10m') {
                _settings.instagramDataRefreshRate = '6h';
            }
        } else if (tier === 1) {
            if (_settings.desktop.numberOfPosts === 'unlimited' || _settings.desktop.numberOfPosts === '100') {
                _settings.desktop.numberOfPosts = '50';
            }

            if (_settings.mobile.numberOfPosts === 'unlimited' || _settings.mobile.numberOfPosts === '100') {
                _settings.mobile.numberOfPosts = '50';
            }

            if (_settings.instagramDataRefreshRate === '10m' || _settings.instagramDataRefreshRate === '6h') {
                _settings.instagramDataRefreshRate = '12h';
            }
        } else if (tier === 0) {
            _settings.desktop.numberOfPosts = '20';
            _settings.mobile.numberOfPosts = '20';
            _settings.desktop.removeWatermark = false;
            _settings.mobile.removeWatermark = false;
            _settings.desktop.customCSS = '';
            _settings.mobile.customCSS = '';
            _settings.instagramDataRefreshRate = '24h';
        }

        dispatch(
            saveSettings({
                settings: _settings,
                pageId
            })
        );
    };

    return (
        <div
            className="apply-font"
            style={{
                padding: 5,
                backgroundColor: designSettings.backgroundColor,
                direction: layoutSettings.direction
            }}
            ref={mainRef}
        >
            <Header
                avatarUrl="https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1095249842.jpg"
                username={profile?.username || 'Lorem ipsum'}
                posts={profile?.media_count || 10}
                followers={110}
                following={1200}
                descriptionTitle="Lorem ipsum dolor"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor"
                layoutSettings={layoutSettings}
                displaySettings={displaySettings}
                designSettings={designSettings}
                isBusiness={!profileType || profileType === 'business'}
            />
            <div id="font-picker" style={{ display: 'none' }} />
            {layoutSettings.layout === 'grid' && (
                <div
                    className="gallery"
                    style={{
                        direction: layoutSettings.direction,
                        gridTemplateColumns: `repeat(${layoutSettings.columns}, 1fr)`,
                        gridGap: +layoutSettings.spacing
                    }}
                >
                    {postsToDisplay.map((post, index) => (
                        <MediaThumbnail
                            key={index}
                            post={post}
                            ratio={layoutSettings.ratio}
                            cornerRadius={designSettings.cornerRadius}
                            openBehavior={settings[deviceType].general.openLinks}
                            showWatermark={!displaySettings.removeWatermark}
                            setPostToOpen={openPost}
                        />
                    ))}
                </div>
            )}
            {layoutSettings.layout === 'slider' && (
                <Carousel
                    posts={posts}
                    displaySettings={displaySettings}
                    layoutSettings={layoutSettings}
                    designSettings={designSettings}
                    openBehavior={settings[deviceType].general.openLinks}
                    openPost={openPost}
                />
            )}
            {postsToDisplay.length < posts.length && displaySettings.loadMore && layoutSettings.layout !== 'slider' && (
                <div className="load-more">
                    <button
                        className="load-more__button apply-font"
                        style={{ backgroundColor: designSettings.buttonsColor, color: designSettings.buttonsTextColor }}
                        onClick={showMore}
                    >
                        Load more
                    </button>
                </div>
            )}
        </div>
    );
}

export default Feed;
